
const loadConfig = async ( filename ) => {
	try {
		return await( ( await fetch( filename ) ).json() );
	} catch( err ) {
		console.log( err );
	}
};

const loadData = async ( filename ) => {
	try {
		const file = await( ( await fetch( filename ) ).text() );
		return CSVToJSON( file, ';' );
	} catch( err ) {
		console.log( err );
	}
};

const CSVToJSON = (data, delimiter = ',') => {


	const titles = data.slice(0, data.replace(/(\r)/gm, "").indexOf('\n')).split(delimiter);
	return data
		.slice(data.indexOf('\n') + 1)
		.split('\n')
		.map(v => {
			const values = v.split(delimiter);
			return titles.reduce(
				(obj, title, index) => ((obj[title] = values[index].trim()), obj),
				{}
			);
		});
};

export {
	loadData,
	loadConfig,
}
