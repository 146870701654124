import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Spinner, VStack } from '@chakra-ui/react';
import { Header } from '../Components/Header';
import Index from '../Pages/Index';
import TypeSelect from '../Pages/TypeSelect';
import Blauwdruk from '../Pages/Blauwdruk';
import { loadConfig } from '../Util/loader';

export default function Layout() {

	const [ config, setConfig ] = useState( null );

	const [ page, setPage ] = useState( 0 );
	const [ type, setType ] = useState('');
	const callbacks = {
		next: () => { setPage(page+1) },
		prev: () => { setPage(page-1) },
		selectType: ( type ) => { setType( type ); callbacks.next() }
	}

	const initConfig = async () => {
		setConfig( await loadConfig( '/config.json' ) );
	}

	useEffect( () => {
		initConfig();
	}, [] );

	let content = '';
	if ( config
	     && config.hasOwnProperty( 'types' )
	     && config.hasOwnProperty( 'pages' )
	) {
		if ( ! config.pages[ page ] ) {
			content = 'Page not found';
		}

		const currentPage = config.pages[ page ];
		switch ( currentPage.type ) {
			case 'index':
				content = <Index page={ currentPage } callbacks={ callbacks } />
				break;
			case 'typeselect':
				content = <TypeSelect page={ currentPage } types={ config.types } callbacks={ callbacks } />
				break;
			case 'blauwdruk':
				content = <Blauwdruk typeInfo={ config.types.filter( ( item ) => item.type === type )[0] ?? {} } type={ type } callbacks={ callbacks } />
				break;
		}
	} else {
		content = <Spinner thickness='4px' color='brand.500' size='xl' />
	}

	return (
		<Box fontSize="xl">
			<Grid minH="100vh">
				<VStack>
					<Header onReturn={ ( page ) ? callbacks.prev : null } />
					<Container maxW={'container.xl'} paddingY={8}>
						{ content }
					</Container>
				</VStack>
			</Grid>
		</Box>
	)
}
