import React from "react";
import { Box, Button, Flex, Heading, Image, List, ListIcon, ListItem } from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/all';
import { CardContainer } from '../CardContainer';

export const Details = props => {

	const {
		details,
		callbacks
	} = props;

	return (
		<Flex gap={8} direction={{ base: 'column-reverse', md: 'row' }} justifyContent="space-between">
			<CardContainer
				atts={ { maxWidth: { base: 'none', md: "50%" }, flexGrow: 1 } }
				header={ details.header && <Heading size="xl">{ details.header }</Heading> }
				body={
					<>
						{ details.title &&
						  <Heading size="lg">{ details.title }</Heading>
						}
						{ details.text && <div dangerouslySetInnerHTML={ { __html: details.text } }></div> }
						{ details.list &&
						  <List gap={2} marginTop={ 6 }>
							  { details.list.map( ( item, index ) => {
								  return <ListItem key={ index }>
									  <ListIcon as={MdCheckCircle} color='brand.500' />
									  { item }
								  </ListItem>
							  } ) }
						  </List>
						}
					</>
				}
				footer={ ( callbacks && details.actions ) &&
			         <>
				         { details.actions.map( ( item, index ) => {
					         return <Button key={ index } minWidth="50%" color={'white'} onClick={ callbacks[ item.action ] }>{ item.text }</Button>
				         } ) }
			         </>
				}
			/>
			{ details.image &&
			  <Box width='auto' maxWidth="50%">
				  <Image src={ details.image }/>
			  </Box>
			}
		</Flex>
	)
};
