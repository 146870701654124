import React from 'react';
import {
	Button, Image,
	Flex, Heading, List, ListIcon, ListItem, Box,
} from '@chakra-ui/react';
import { CardContainer } from '../Components/CardContainer';
import { MdCheckCircle } from 'react-icons/all';
import { Details } from '../Components/Details';

export default function Index( props ) {

	const {
		page,
		callbacks
	} = props;

	return <Details details={ page } callbacks={ callbacks } />
}
